import AxTable from '@/components/Table/Table.vue';
import AxColumnSelector from '@/components/Table/ColumnSelector.vue';
import AxExport from '@/components/Table/Export.vue';

export { AxColumnSelector, AxExport };

/**
 * @deprecated DON'T USE/CHANGE THIS COMPONENT IN THIS LOCATION, Instead use/change the cosmos component:
 *  import AxTable from '@ax/cosmos/components/Table';
 */
export default AxTable;
