var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.$scopedSlots['actions-left'] ||
      _vm.$scopedSlots['actions-right'] ||
      _vm.columnSelect
    )?_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"tw-flex-grow",attrs:{"cols":"auto"}},[_vm._t("actions-left")],2),_c('v-col',{attrs:{"cols":"auto"}},[_vm._t("actions-right"),(_vm.columnSelect)?_c('column-selector',{attrs:{"value":_vm.tableHeaders,"columns":_vm.headers,"static-columns":_vm.staticHeaders},on:{"input":_vm.columnsUpdated}}):_vm._e()],2)],1):_vm._e(),_c('v-data-table',_vm._g(_vm._b({staticClass:"ax-table",class:{
      'ax-table--bordered': _vm.hasBorder,
      'ax-table--all-expanded': _vm.allExpanded,
    },attrs:{"must-sort":_vm.forcedSort,"headers":_vm.tableHeaders,"footer-props":_vm.tableFooterProps,"expanded":_vm.allExpanded ? _vm.items : _vm.expanded},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'v-data-table',_vm.tableProps,false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }