import { BITWISE_DATE_MAP, format12HourTime } from '@/utils/date-time';

/**
 * The scheduler component days, weeks, month, and time from the html form
 */
export interface SchedulerTimes {
  days?: number[];
  weeks?: number[];
  months?: number[];
  time?: string;
}

/**
 * The bitwise component days, weeks, month, and time that is sent to backend service endpoint(s)
 */
export interface BitwiseSchedulerTimes {
  schedule_days: number;
  schedule_weeks_of_month: number;
  schedule_months: number;
  schedule_time: string;
}

/**
 * The default scheduler component time input value
 */
export const DEFAULT_TIME = '12:00 AM';

/**
 * The timeout value for throttling the getNewSchedule call
 */
export const TIMER_LIMIT = 600;

/**
 * The list of days to be used by the scheduler form.
 * Converted from the BITWISE_DATE_MAP.
 */
export const DAYS_LIST = BITWISE_DATE_MAP.days.values.map((day, i) => {
  return {
    id: i,
    name: day === 'Thursday' ? 'thurs' : day.slice(0, 3),
    value: day,
  };
});

/**
 * The list of weeks to be used by the scheduler form.
 * Converted from the BITWISE_DATE_MAP.
 */
export const WEEKS_LIST = BITWISE_DATE_MAP.weeks.values.map((n, i) => {
  return { id: i, name: n, value: n };
});

/**
 * The list of months to be used by the scheduler form.
 * Converted from the BITWISE_DATE_MAP.
 */
export const MONTHS_LIST = BITWISE_DATE_MAP.months.values.map((month, i) => {
  return {
    id: i,
    name: month.slice(0, 3),
    value: month,
  };
});

export const ALL_DAYS_SELECTED = BITWISE_DATE_MAP.days.values.map(
  (day, i) => i,
);
export const ALL_WEEKS_SELECTED = BITWISE_DATE_MAP.weeks.values.map(
  (week, i) => i,
);
export const ALL_MONTHS_SELECTED = BITWISE_DATE_MAP.months.values.map(
  (month, i) => i,
);

/**
 * The default scheduler bitwise values to populate the form.
 */
export const EMPTY_DEFAULT_SCHEDULER_BITWISE_VALUES: BitwiseSchedulerTimes = {
  schedule_days: 0,
  schedule_weeks_of_month: 0,
  schedule_months: 0,
  schedule_time: format12HourTime(DEFAULT_TIME),
};
